<template>
    <div class="ngram-cell" @click="selectNgram(ngram)">
        <div v-if="addingNgramsToNegative" class="spinner-container">
            <Spinner />
        </div>
        <Checkbox v-else :model-value="initialNgramSelection.includes(ngram)" />
        <Spacer width="1.25rem" />

        <EntityPill
            v-if="entityPillState === 'default'"
            :type="Improvement.LocationEntity.NGram"
            :content="ngram"
        />
        <Popout
            v-else-if="entityPillState === 'added-negative'"
            v-model="showPopout"
            trigger="mouseenter"
            placement="top"
            :maxWidth="720"
            :borderRadius="28"
            :offset="[-24, 16]"
            :delay="[400, 0]"
            :zIndex="9999999999999"
            wrapperClasses="keyword-source-popout-wrapper"
        >
            <div class="added-negative-keyword">
                <EntityPill :type="Improvement.LocationEntity.NegativeKeyword" :content="ngram" />
                <Spacer width="0.5rem" />
                <img class="info-circle-icon" src="@/assets/img/icon-info.svg" />
            </div>

            <template #content>
                <perfect-scrollbar class="keywords-list">
                    <KeywordInfo
                        v-for="keywordInfoProps in keywordInfoPropsArray"
                        v-bind="keywordInfoProps"
                        negative
                    >
                        <template #keyword-info>
                            <Text color="gray" size="f-9">
                                Negative added <b>{{ formattedDate }}</b
                                >.</Text
                            >
                        </template>
                    </KeywordInfo>
                </perfect-scrollbar>
            </template>
        </Popout>
        <!-- Shiny pills -->
        <div v-else class="added-negative-keyword">
            <EntityPill :type="Improvement.LocationEntity.NGram" :content="ngram" />
            <Spacer width="0.5rem" />
            <Tooltip
                :content="shinyTooltipContent"
                :max-width="276"
                flex
                placement="top"
                :offset="[0, 8]"
                :delay="[300, 0]"
            >
                <Pulse />
            </Tooltip>
        </div>
    </div>
</template>

<script setup lang="ts">
import {
    Spacer,
    Checkbox,
    EntityPill,
    Spinner,
    Popout,
    Text,
    Tooltip,
    usePercent,
} from '@opteo/components-next'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { Improvement, NgramTool } from '@opteo/types'
import KeywordInfo from './KeywordInfo.vue'
import Pulse from './Pulse.vue'
import { computed, ref } from 'vue'
import formatDate from 'date-fns/format'

const props = defineProps<{
    ngram: string
    dateAdded?: string
    negativeKeywordsDestinations?: NgramTool.negativeKeywordsDestinations
    industryPerformanceRatio: number
    ga4Sessions: number
    ga4EngagedSessions: number
    avgEngagementRate: number
    hasPoorEngagement: boolean
    hasPoorIndustryPerformance: boolean
}>()

const formattedDate = computed(() => {
    if (!props.dateAdded) return ''
    return formatDate(new Date(props.dateAdded), 'do LLLL yyyy')
})

const { selectNgram, initialNgramSelection, addingNgramsToNegative } = useNGramTool()

const shinyTooltipContent = computed(() => {
    if (entityPillState.value === 'industry-negative') {
        return `This n-gram performs <b>${props.industryPerformanceRatio}x</b> worse than average across accounts in your industry.`
    }

    if (entityPillState.value === 'engagement-negative') {
        const bounceRate = usePercent({
            value: 1 - props.ga4EngagedSessions / props.ga4Sessions,
            decimalPlaces: 0,
        }).displayValue.value

        const avgBounceRate = usePercent({
            value: 1 - props.avgEngagementRate,
            decimalPlaces: 0,
        }).displayValue.value
        return `This N-Gram has a higher bounce rate than average (<b>${bounceRate}</b> n-gram vs. <b>${avgBounceRate}</b> average).`
    }
})

const entityPillState = computed(() => {
    if (props.negativeKeywordsDestinations) {
        return 'added-negative' // has been added as kw
    }

    if (props.hasPoorIndustryPerformance) {
        return 'industry-negative' // similar domains perform poorly
    }

    if (props.hasPoorEngagement) {
        return 'engagement-negative' // high bounce rate
    }

    return 'default'
})

const keywordInfoPropsArray = computed(() => {
    const array: {
        keyword: string
        accountLevel?: boolean
        adGroupName?: string
        campaignName?: string
        negativeList?: string
        negative?: boolean
        loading?: boolean
    }[] = []

    const negativeKeywordsDestinations = props.negativeKeywordsDestinations

    if (!negativeKeywordsDestinations) return []

    if (negativeKeywordsDestinations.accountLevel) {
        array.push({
            keyword: props.ngram,
            accountLevel: true,
        })
    }

    if (negativeKeywordsDestinations.campaigns) {
        negativeKeywordsDestinations.campaigns.forEach(campaign => {
            array.push({
                keyword: props.ngram,
                campaignName: campaign.campaignName,
            })
        })
    }

    if (negativeKeywordsDestinations.adGroups) {
        negativeKeywordsDestinations.adGroups.forEach(adGroup => {
            array.push({
                keyword: props.ngram,
                adGroupName: adGroup.adGroupName,
                campaignName: adGroup.campaignName,
            })
        })
    }

    if (negativeKeywordsDestinations.sharedSets) {
        negativeKeywordsDestinations.sharedSets.forEach(sharedSet => {
            array.push({
                keyword: props.ngram,
                negativeList: sharedSet.sharedSetName,
            })
        })
    }

    return array
})

const showPopout = ref(false)
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.added-negative-keyword {
    display: inline-flex;
    align-items: center;
}

.ngram-cell {
    display: flex;
    align-items: center;
}

.added-negative-keyword {
    overflow: hidden;
    position: relative;
    width: 100%;
}
// .added-negative-keyword::after {
//     content: '';
//     background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
//     @include absolute;
//     top: 0;
//     right: -1rem;
//     width: 6rem;
//     bottom: 0;
// }

:deep(.entity-pill.n-gram) {
    max-width: calc(100% - 4rem);
}

.keywords-list {
    @include pa-20;
    min-width: 352px;
    max-height: 362px;
    @include br-20;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.footer {
    border-top: 1px solid;
    @include opteo-border;
    display: flex;
    @include pt-16;
    padding-bottom: 1.125rem;
    @include ph-24;
}

.info-circle-icon {
    width: 1rem;
    cursor: pointer;
    position: relative;
    z-index: 10;
}

/* :deep(.industry-negative.entity-pill.n-gram) {
    background: radial-gradient(at bottom left, #cd78eb, #00d9c3);
    color: $opteo-white;
}

:deep(.industry-negative.entity-pill.n-gram svg path) {
    fill: $opteo-white !important;
} */
</style>
