import { Account, Platform } from '@opteo/types'
import pDebounce from 'p-debounce'
import { unref } from 'vue'

type scrollBehavior = 'smooth' | 'auto'
export const scrollToSelector = (
    selector: string,
    behavior: scrollBehavior = 'smooth',
    yOffset = 32 // Scroll a bit further down to make space for top bar
) => {
    const element = document.querySelector(selector)
    if (!element) {
        console.warn('Element does not exist: ' + selector)
        return
    }

    const elementPosition = element.getBoundingClientRect().top

    const offsetPosition = elementPosition + window.pageYOffset - yOffset

    window.scrollTo({
        top: offsetPosition,
        behavior,
    })
}

export const scrollTo = (elementId: string, behavior: scrollBehavior = 'smooth', yOffset = 32) => {
    const selector = `#${elementId}`
    scrollToSelector(selector, behavior, yOffset)
}

export const scrollToTop = (behavior: scrollBehavior = 'smooth') => {
    window.scrollTo({ top: 0, behavior })
}

// useful when you want to scroll in the parent div, not the whole document
export const nestedScrollToSelector = (
    selector: string,
    behavior: 'smooth' | 'auto' = 'smooth'
) => {
    const element = document.querySelector(selector)

    if (element) {
        // centre the element in the viewport.
        element.scrollIntoView({ behavior, block: 'center' })
    } else {
        console.warn(`could not scroll to ${selector} because it does not exist`)
    }
}

export const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms))

// Wrap a function in promiseDebounce to only call your function at the _end_
// of a chain of quick successive calls.
export const promiseDebounce = pDebounce

export const snakeCaseToTitleCase = (enum_string: string): string => {
    return enum_string
        .toLowerCase()
        .split('_')
        .map(element => {
            return `${element.charAt(0).toUpperCase()}${element.slice(1)}`
        })
        .join(' ')
}

export const snakeCaseToLowerCase = (enum_string: string): string => {
    return enum_string
        .toLowerCase()
        .split('_')
        .map(element => {
            return `${element.charAt(0)}${element.slice(1)}`
        })
        .join(' ')
}

export const pluralise = (count: number, singular: string, plural: string) =>
    unref(count) === 1 ? singular : plural

export const getNumberWord = (i: number) => {
    // minus 1 to adjust for zero index
    // number argument should be returned as word
    // 1 → One
    // 7 → Seven
    const matchedIndex = i - 1
    const words = [
        'One',
        'Two',
        'Three',
        'Four',
        'Five',
        'Six',
        'Seven',
        'Eight',
        'Nine',
        'Ten',
        'Eleven',
        'Twelve',
        'Thirteen',
        'Fourteen',
        'Fifteen',
        'Sixteen',
        'Seventeen',
        'Eighteen',
        'Nineteen',
        'Twenty',
    ]

    return words[matchedIndex]
}

export const fromMicros = (micros: number) => micros / 1_000_000

// This function is like toFixed(), but it rounds correctly (eg (1.005).toFixed(2) === 1, but this function returns 1.01)
export const toFixedAccurate = (value: number, decimals: number): string => {
    return Number(Math.round(+(value + 'e' + decimals)) + 'e-' + decimals).toFixed(decimals)
}

export const urlValidator = (_url: string) => {
    let url

    const isValidUrl = () => {
        try {
            url = new URL(_url)
        } catch (_) {
            return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
    }
    if (!isValidUrl()) {
        return `Please enter a valid URL prefixed with http:// or https://`
    }
}

export const isOdd = (num: number) => num % 2 == 1

export const calculateCpa = ({ cost, conversions }: { cost: number; conversions: number }) => {
    if (cost === 0 && conversions === 0) {
        return 0
    }

    return cost / conversions
}

/**
 * @description Same as calculateCpa, but will avoid generating huge CPAs when the number of conversions is fractional.
 * We should not use this everywhere, see https://github.com/Opteo/luckymetrics/pull/4969
 * @param cost {number} Can be money or micros.
 * @param all_converions {number} If 0 then the cost will be returned.
 */

// Same as calculateCpa, but will avoid generating huge CPAs when the number of conversions is fractional.
export function calculateCpaClamped({
    cost,
    conversions,
}: {
    cost: number
    conversions: number
}): number {
    if (conversions < 0.25 && conversions !== 0) {
        return cost
    }

    return calculateCpa({ cost, conversions })
}

export const calculateRoas = ({
    cost,
    conversionsValue,
}: {
    cost: number
    conversionsValue: number
}) => {
    if (cost === 0 && conversionsValue === 0) {
        return 0
    }

    return conversionsValue / cost
}

/**
 * @description Sorts a collection (an array of objects) using one of its values or nested values by a second array of strings.
 * @example const sortedItems = sortCollectionBy({ collectionToSort: items, sortingValue: (item) => item.campaign.resource_name, arrayToSortBy: campaignResourceNamesByCost })
 * @param args.collectionToSort - The array of objects to be sorted
 * @param args.sortingValue - A callback with the value to be sorted by
 * @param args.arrayToSortBy - An array of strings to sort by
 * @returns A sorted collection
 */
export const sortCollectionBy = <T extends Record<string, unknown>>({
    collectionToSort,
    sortingValue,
    arrayToSortBy,
}: {
    collectionToSort: T[]
    sortingValue: (item: T) => string
    arrayToSortBy: string[]
}) => {
    const sorted = collectionToSort.sort(
        (a, b) => arrayToSortBy.indexOf(sortingValue(a)) - arrayToSortBy.indexOf(sortingValue(b))
    )

    return sorted
}

export const getPlatformFromId = (id: Account.ID): Platform.Platform | undefined => {
    if (id.startsWith(Platform.PlatformIdPrefix.GoogleAds)) return Platform.Platform.GoogleAds
    if (id.startsWith(Platform.PlatformIdPrefix.MicrosoftAds)) return Platform.Platform.MicrosoftAds

    if (id.startsWith(Platform.PlatformIdPrefix.MetaAds)) return Platform.Platform.MetaAds
}

export * from './downloadCsv'
export * from './extractIdFromResourceName'
export * from './openInNewTab'
export * from './downloadExcelFile'
