<template>
    <div class="page-wrapper">
        <ScorecardContent
            v-if="isScorecardAvailable"
            :scorecardBody="scorecard.body"
            :accountInfo="scorecard.account"
            :createdAt="scorecard.createdAt"
            :overviewScore="scorecard.overviewScore"
            :showSection="sectionShown"
            mode="pdf"
        />
    </div>
</template>

<script lang="ts">
import lzstring from 'lz-string'
import { computed, ref, provide } from 'vue'
import { useRouter } from 'vue-router'

import ScorecardContent from '@/components/scorecard/ScorecardContent.vue'
import { handleIsScorecardAvailable } from '@/components/scorecard/utils'

import type { Scorecard } from '@opteo/types'

export default {
    name: 'ScorecardPdf',
    components: {
        ScorecardContent,
    },
    setup() {
        const router = useRouter()
        const scorecardDataString = lzstring.decompressFromEncodedURIComponent(
            router.currentRoute.value.query.scorecard as string
        )

        const sectionShown = router.currentRoute.value.query
            .section as Scorecard.SectionTypeIncCover

        const scorecard = ref<Scorecard.SingleScorecardFull>(JSON.parse(scorecardDataString))

        const isScorecardAvailable = computed(() => handleIsScorecardAvailable(scorecard.value))

        provide('isPdf', true)

        return {
            isScorecardAvailable,
            scorecard,
            sectionShown,
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/theme.scss';
@import '@/assets/css/variables.scss';

.page-wrapper {
    padding-top: 6rem;
    padding-bottom: 6rem;
    max-width: 1148px;
    margin: 0 auto;
}
</style>
